import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    getAllUser: {},
    loading: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: ({
        getAllUsers: (state) => {
            state.loading = true
        },
        setGetAllUsers: (state, action) => {
            state.loading = false
            state.getAllUser = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { getAllUsers,setGetAllUsers,onErrorStopLoad } = dashboardSlice.actions

export default dashboardSlice.reducer