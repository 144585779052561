const ApiPath = {
    AuthApiPath: {
        LOGIN_ADMIN: '/api/v1/admin/login',
        ADMIN_FORGET_PASSWORD: '/api/v1/admin/forgot/password',
        ADMIN_VERIFY_OTP: '/api/v1/admin/verify/forgot/password/otp',
        ADMIN_RESEND_OTP: '/api/v1/admin/resend/otp',
        LOGOUT_ADMIN: '/api/v1/admin/logout',
        ADMIN_RESET_PASSWORD:'/api/v1/admin/reset/password'
    },
    DashboardApiPath: {
        GET_ALL_USERS: '/api/v1/admin/all/company',
    },

   
}

export default ApiPath;
