import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import { getAllUsers } from "../../../redux/slices/dashboard"
import { useDispatch } from 'react-redux';
import ReactPaginate from "react-paginate";
import { useDashboardSelector } from '../../../redux/selector/dashboard';

const Support = () => {

    const dispatch = useDispatch();
    const dashboardSelector = useDashboardSelector();
    const [pageLimit, setPageLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [getAllUser, setGetAllUser] = useState("");
    const [selectedSort, setSelectedSort] = useState("");
    const [selectedMembershipFilter, setSelectedMembershipFilter] = useState("");

    const getAllUsersList = (page, limit, selectedSort, selectedMembershipFilter) => {
        let params = {
            page: page ? page : currentPage,
            limit: limit ? limit : pageLimit,
            sort: selectedSort ? selectedSort : "",
            search: selectedMembershipFilter ? selectedMembershipFilter : ""
        }
        dispatch(getAllUsers({
            ...params, cb(res) {
                if (res.data) {
                    console.log(res.data, "res.data");
                    setGetAllUser(res.data?.payload?.data)
                    setTotalPage(res.data?.payload?.total_pages)
                }
            }
        }))
    }


    // sort listing data 
    const handleSort = (Sorting) => {
        setSelectedSort(Sorting)
        getAllUsersList(1, 10, Sorting, selectedMembershipFilter)
    }

    // sort listing data 
    const searchMembership = (membership) => {
        setSelectedMembershipFilter(membership)
        getAllUsersList(1, 10, selectedSort, membership)
    }

    //on change page pagination
    const handlePageClick = (e) => {
        const selectedMembership = e.selected + 1;
        setCurrentPage(selectedMembership);
        getAllUsersList(selectedMembership, pageLimit, selectedSort, selectedMembershipFilter);
    };


    useEffect(() => {
        getAllUsersList()
        document.title = "Dashboard";
    }
        , [])
        
    return (
        <div className='containerBox adminDashbord'>
            <h3 className='headTxtBlueSmall mb-3'>Support Tickets</h3>
            <div className='searchFilter mt-5'>
                <div className='d-flex'>
                    <select className='supportFilter me-3' value={selectedMembershipFilter} onChange={(e) => searchMembership(e.target.value)}>
                        <option value="">Search By Membership</option>
                        <option value="1">Platinum</option>
                        <option value="2">Gold</option>
                        <option value="3">Bronze</option>
                    </select>
                    <select className='supportFilter'>
                        <option>Search by Category</option>
                    </select>
                </div>
                <select className='supportFilter' value={selectedSort} onChange={(e) => handleSort(e.target.value)}>
                    <option value="">Filter List ( A to Z )</option>
                    <option value="1">A to Z</option>
                    <option value="2">Z to A</option>
                </select>
            </div>

            {dashboardSelector.loading ?
                <>
                    <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                </>
                :
                <>
                    <table className='manageUserTable mt-5'>
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Email Address</th>
                                <th>Membership Level</th>
                                <th>Tools</th>
                                <th>Member Since</th>
                            </tr>
                        </thead>
                        {getAllUser?.length ?
                            <>
                                {getAllUser?.map((data, index) => {
                                    let membershipLevel
                                    if (data?.user_details?.membership_type === 1) {
                                        membershipLevel = "Platinum"
                                    }
                                    else if (data?.user_details?.membership_type === 2) {
                                        membershipLevel = "Gold"
                                    }
                                    else if (data?.user_details?.membership_type === 3) {
                                        membershipLevel = "Bronze"
                                    }
                                    else {
                                        membershipLevel = "N/A"
                                    }
                                    console.log(membershipLevel, "membershipLevel");
                                    return (
                                        <>
                                            <tbody>
                                                <tr>
                                                    <td>{data?.company_name}</td>
                                                    <td>{data?.user_details?.email}</td>
                                                    <td>{membershipLevel}</td>
                                                    <td>
                                                        <img src={Images.greenWarning} alt="icon" className='trashIcon' />
                                                    </td>
                                                    <td>January 2021</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    )
                                })
                                }
                            </>
                            : <b>No Users Found</b>
                        }
                    </table>
                </>
            }
            <div className="col-12 ">
                <div className="customPagination mt-4">
                    <div className="pagination">
                        {getAllUser?.length > 0 ? (
                            <ReactPaginate
                                // nextLabel={'Next ' + <span>{'>>'}</span>}
                                nextLabel={<span>
                                    Next <span className="double-arrow">{">>"}</span>
                                </span>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                // pageRangeDisplayed={2}  // Show only the starting 2 and last 2 pages
                                // marginPagesDisplayed={2}
                                // pageRangeDisplayed={pageCount}
                                pageCount={totalPage}
                                // previousLabel={'<< Previous'}
                                previousLabel={<span>
                                    <span className="double-arrow h5">{"<<"}</span> Previous
                                  </span>}
                                renderOnZeroPageCount={null}
                            />
                        ) : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support