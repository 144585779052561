import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setAdminLogin,setAdminForgetPasswodVerifyOtp,setAdminForgetPassword,setAdminResendOtp,setAdminResetPassword,setAdminLogout} from '../../slices/auth';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';

function* adminLogin(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.LOGIN_ADMIN, action.payload = action.payload);
    if (resp.status) {
      localStorage.setItem("adminAuthToken", resp.data.payload.token ? resp.data.payload.token : "")
      yield put(setAdminLogin(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAdminLogin({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* adminForgetPassword(action) {
  console.log(action,"action.res.data");
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_FORGET_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      yield put(setAdminForgetPassword(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAdminForgetPassword({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* adminVerifyOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_VERIFY_OTP, action.payload = action.payload);
    if (resp.status) {
      yield put(setAdminForgetPasswodVerifyOtp(resp.data.payload));
      yield call(action.payload.cb, resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAdminForgetPasswodVerifyOtp({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* adminResendOtp(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_RESEND_OTP, action.payload = action.payload);
    if (resp.status) {
      yield put(setAdminResendOtp(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAdminResendOtp({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* adminResetPassword(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_RESET_PASSWORD, action.payload = action.payload);
    if (resp.status) {
      yield put(setAdminResetPassword(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action?.res?.data?.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAdminResetPassword({}));
    yield put(onErrorStopLoad())
    toast.error(e?.response?.data?.msg);
  }
}

function* adminLogout(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.LOGOUT_ADMIN, action.payload = action.payload);
    if (resp.status) {
      yield call(action.payload.cb, action.res = resp)
      localStorage.removeItem('adminAuthToken');
      localStorage.removeItem('persist:root');
      // toast.success(action.res.data.msg);
      yield put(setAdminLogout())
    }
    else {
      throw resp
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}

function* authSaga() {
  yield all([
    takeLatest('auth/adminLogin', adminLogin),
    takeLatest('auth/adminForgetPassword', adminForgetPassword),
    takeLatest('auth/adminForgetPasswodVerifyOtp', adminVerifyOtp),
    takeLatest('auth/adminResendOtp', adminResendOtp),
    takeLatest('auth/adminResetPassword', adminResetPassword),
    takeLatest('auth/adminLogout', adminLogout),
  ])
}

export default authSaga;