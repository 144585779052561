import React, { useEffect } from 'react'
import AwesomeSlider from 'react-awesome-slider';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Images from "../../../utilities/images";
import OwlCarousel from 'react-owl-carousel';
const AboutUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = [1, 2, 3]

    useEffect(() => {
      if(location.hash){
          let element = document.getElementById(location.hash.replace("/aboutUs#", ""));
          element.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
      }
      document.title = "About-us";
  }, []);
  const SectionToScroll = (flag) => {
    var section = document.getElementById(flag);

    if(section){
      if (section.id == "thefounder") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "mission") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "leadership") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      else if (section.id == "press") {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
  }
  const handleRedirectPage  = () => {
    navigate("/contactUs")
  }

  return (

    <div className='aboutUs'>
    {/* Start HomePage Section */}
    <section className='homeBanner'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12'>
            <div className='aboutContainer'>
              <div className='mb-3 mb-xl-4'>
                <h3 className='bannerHead'>
                  About <b>GetOPP$</b>
                </h3>
                <p className='bannerTxt my-5'>
                <b>GetOPP$</b> is a dynamic social media networking platform matchmaking Buyers and Suppliers for contracting opportunities.
                  {/* <b>GetOPP$</b> is a matchmaking service for all <br />companies looking for contracting opportunities. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End HomePage Section */}
    {/* Start AboutMenus Section */}
    <section className='aboutMenus'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='aboutMenusContainer'>
              <li className='aboutItem'>
                <button onClick={() => SectionToScroll("thefounder")} className='aboutLinks'>The Founder</button>
              </li>
              <li className='aboutItem'>
                <button onClick={() => SectionToScroll("mission")} className='aboutLinks'>MISSION</button>
              </li>
              <li className='aboutItem'>
                <button onClick={() => SectionToScroll("leadership")} className='aboutLinks'>LEADERSHIP</button>
              </li>
              <li className='aboutItem'>
                <button onClick={() => SectionToScroll("press")} className='aboutLinks'>PRESS</button>
              </li>
              <li className='aboutItem'>
                <button onClick={() => handleRedirectPage()} className='aboutLinks'>CONTACT</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    {/* Start AboutMenus Section */}
    {/* Start AboutFounder Section */}
    <section className='aboutFounder' id='thefounder'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12'>
            <div className='mb-4'>
              <h3 className='bannerHead'>About <b>THE FOUNDER</b></h3>
              {/* <p className='otherLink'><b>GetOPP$ Visionary, Marilyn Booker is a Corporate OG.</b></p> */}
              <p className='otherLink'><b>GetOpp$ Visionary, Marilyn Booker is a Corporate OG</b></p>
            </div>
            <p className='bannerTxt'>
              {/* She is a wave maker and has been influencing diversity, equity, and inclusion programs in the United States and around the globe for the past 30 years. With a seat at the head table for the majority of her career, Marilyn understands the challenges faced by many suppliers to connect with buyers. That is how and why her vision for GetOPP$ came to life. */}
              She is a wave maker and has been influencing diversity, equity, and inclusion programs in the United States and around the globe for the past 30
years. After graduating from Spelman College and IIT Chicago Kent College of Law, she earned a seat at the head table. Marilyn understands the
challenges faced by many suppliers to connect with buyers. That is how and why her vision for GetOpp$ came to life.
            </p>
            <p className='bannerTxt'>
              {/* Marilyn has worked with both private and public sectors of enterprise corporations and government entities. She has experienced how organizations’ internal and external procurement and inclusion processes “claim to work” and how “they actually work”. It is this experience that puts her in the unique position to address challenges faced by buyers looking for suppliers and the challenges of small businesses in their quest to create and maintain sustainable companies. */}
              Marilyn has worked with both private and public sectors of enterprise corporations and government entities. She has experienced how
organizations’ internal and external procurement and inclusion processes are designed to work and the headwinds often faced. It is this experience
that puts her in the unique position to address challenges faced by buyers looking for suppliers and the challenges of all businesses, particularly
small businesses, in their quest to create and maintain sustainable companies.

            </p>
            <p className='bannerTxt'>
              {/* Marilyn and team are creating a tool to disrupt the old ways of connecting businesses to each other while providing access to education focused on long term sustainability and financial security. */}
              Marilyn and team have created a tool to disrupt the old ways of connecting businesses to each other while providing access to education focused on
long term sustainability and financial security.

            </p>
            <p className='bannerTxt fw-bolder'>
              {/* GetOpp$ will provide unprecedented connections between buyers and suppliers. */}
              GetOpp$ will provide unprecedented connections between Buyers and Suppliers.

            </p>
          </div>
          <div className='col-lg-4'  >
            <figure className='aboutLadymain_'>
              <img src={Images.aboutLady} className='img-fluid aboutLady' alt='BuyerService' />
            </figure>
          </div>
        </div>
      </div>
    </section>
    {/* End AboutFounder Section */}
    {/* Start Mission Section */}
    <section className='mission' id="mission">
      <div  className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='heading_Blue mb-3'>
              Purpose <b>AND MISSION</b>
            </h4>
            <p className='innerSubtext mb-3'>
              {/* <b>The GetOPP$ mission is to provide a platform for large and small businesses of all hues to connect and engage with each other as a business community, discover new products and services and
foster valuable procurement relationships.</b> */}
            <b>The GetOPP$ mission is to provide a platform for large and small businesses of all hues to connect and engage with each other as a business community.  These connections will allow all types of businesses to discover and present new products and services and foster valuable procurement relationships.</b>
            </p>
            {/* <p className='innerSubtext mt-2 mb-4'>
            The GetOPP$ vision is to help large, small and diverse businesses thrive. Provide Buyer companies with efficiency, organization and real time exposure to a broader and more comprehensive base
of large, small and diverse businesses. Put Supplier companies on the global radar screens of companies that otherwise would not know they existed and to provide business resources that can
play a role in their educational journey. Provide a safe space for Buyers and Suppliers to build trust and create genuine relationships. Many procurement processes are bogged down with
algorithms, checklists and stale databases. Technology is a great tool but people are the value everyone is looking for. To tap into the human behind the desktop, there needs to be a bridge for
connection and communication. GetOPP$ is that bridge bringing all three elements of its mission to bear - connecting real people to real opportunities to real businesses, large, small and diverse.
            </p> */}
            <p className='innerSubtext mt-2 mb-4'>To accomplish its mission, GetOPP$ is designed to: </p>
            <ul className='aboutList mt-2 mb-4'>
              <li>
                <p className='innerSubtext'>Provide Buyer companies with efficiency, organization and real time exposure to a broader and more comprehensive base of large, small and diverse businesses 
                </p>
              </li>
              <li>
                <p className='innerSubtext'>Relieve Buyer companies of the burden of struggling with stale databases as Suppliers update their own company profiles in real time with current, fresh data 
                </p>
              </li>
              <li>
                <p className='innerSubtext'>Put Suppliers on the global radar screens of companies that otherwise would not know they existed 
                </p>
              </li>
              <li>
                <p className='innerSubtext'>Provide business resources that can play a role in the educational journey of up-and-coming businesses 
                </p>
              </li>
              <li>
                <p className='innerSubtext'>Provide a safe space for Buyers and Suppliers to build trust and create genuine relationships 
                </p>
              </li>
            </ul>
            {/* <p className='innerSubtext mt-2 mb-4'>
            In today’s global market, effective marketing, meaningful relationships and that human connection are critical to the success of supplier companies. When these elements are lacking, there is a
procurement void between Buyers and Suppliers. GetOpp$ merges business, marketing and human elements into the buying and supplying process. With well-organized dashboards and direct
communication for opportunities, Buyers and Suppliers can find each other and then reach out to follow up and chat without feeling like they are blocked out by the deep abyss of the cloud.
            </p> */}
            <p className='innerSubtext mt-2 mb-4'>Technology is a great tool, but technology without that human element can be challenging because people are the enhanced value most are looking for.  GetOPP$ is that bridge bringing all of the elements of its mission to bear - connecting real people to real opportunities to real businesses, large, small and diverse. </p>
            {/* <p className='innerSubtext mt-2 mb-4'>
            The GetOPP$ tools leverage technology to bring business professionals closer together. Suppliers can search for contracting opportunities directly based on their specific needs and reach out to
Buyers with a professional message or apply to a specific opportunity. Buyers too can search for and reach out to Suppliers and quickly complete due diligence, post opportunities and strategically
expand their business network. Large, small and diverse companies can highlight business status, certifications, skills and services to clearly attract desired audiences. Everyone can highlight their
accomplishments, intentions and missions with featured highlights.
            </p> */}
            <p className='innerSubtext mt-2 mb-4'>In today’s global marketplace, effective marketing and visibility are critical to the success of any company. By merging business, marketing, social media, technology and relationship building into the Buyer/Supplier relationship, GetOPP$ is filling the void that can often be fatal to companies trying to get their businesses on the map. With well-organized dashboards and direct communication for opportunities, Buyers and Suppliers can find each other and reach out to follow up and chat without feeling like they are blocked out by the deep abyss of the cloud. </p>
            <p className='innerSubtext mt-2 mb-4'>With the GetOPP$ tools, Suppliers can search for contracting opportunities directly based on their specific needs and reach out to Buyers with a message or apply to a specific opportunity. Buyers too can search for and reach out to Suppliers and quickly complete due diligence, post opportunities and strategically expand their business network. Large, small and diverse companies can highlight business status, certifications, skills and services to attract desired audiences. And to enhance their marketing and visibility, everyone has the opportunity to showcase their accomplishments, intentions and missions with featured highlights.</p>
            <p className='innerSubtext mt-2 mb-4'>
              <b>Whether you are a Supplier or a Buyer or Both, GetOPP$ is for you!  </b>
            </p>
            {/* <p className='highLightTxt'>
              <b>Whether you are a Supplier or a Buyer or Both, GetOpp$ is for you!</b>
            </p> */}
          </div>
        </div>
      </div>
    </section>
    {/* End Mission Section */}
    {/* Start Mission Section */}
    <section className='missionBoard' id="leadership">
      <div  className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='heading_Blue mb-5'>
              Leadership
            </h4>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-10'>
            <div className='containerBoard'>
              <figure>
                <img src={Images.containerBoard1} className='boardImg' alt='BuyerService' />
              </figure>
              <artical>
                <b className='innerSubtextBold'> Marilyn F. Booker, President, CEO</b>
                <p className='weeksTxt'>President, CEO</p>
                <p className='innerSubtext mb-2'> An Attorney and 30+ year veteran of Wall Street, Marilyn is an expert in small and minority business having built a world class supplier diversity program for one of the leading global financial services firms.  She has also worked with many of the governmental and trade organizations that support the growth of small and diverse businesses.</p>
              </artical>
            </div>

            <div className='containerBoard'>
              <figure>
                <img src={Images.containerBoard2} className='boardImg' alt='BuyerService' />
              </figure>
              <artical>
                <b className='innerSubtextBold'> Walter K. Booker, Jr. COO</b>
                <p className='weeksTxt'>COO</p>
                <p className='innerSubtext mb-2'> A self-taught technology and business aficionado, Walter understands the intersection of technology and business in ways that will allow him to lead the business in a direction that will continually adapt, evolve and serve the needs of its constituents. He will oversee the development and dissemination of technology to all users of the platform.</p>
              </artical>
            </div>
            {/* <div className='containerBoard'>
              <figure>
                <img src={Images.containerBoard4} className='boardImg' alt='BuyerService' />
              </figure>
              <artical>
                <b className='innerSubtextBold'> Morgan Booker, Treasurer</b>
                <p className='weeksTxt'>Board Member</p>
                <p className='innerSubtext mb-2'>A 2020 graduate of Harvard College, Morgan has spent the last 6 years working for one of the leading Global  media companies both summer and full time. Having worked in the office of the CEO for several of the media company networks, she gained first-hand knowledge of the value of world-class marketing to achieve maximum penetration in any chosen market.</p>
              </artical>
            </div> */}
            {/* <div className='containerBoard'>
              <figure>
                <img src={Images.containerBoard3} className='boardImg' alt='BuyerService' />
              </figure>
              <artical>
                <b className='innerSubtextBold'> Maxwell E. Booker, Board Member</b>
                <p className='weeksTxt'>Board Member</p>
                <p className='innerSubtext mb-2'> A May 2023 graduate of Harvard College, Max majored in Economics and has experience in the private equity space. He has worked with the family on finances of the company and understands the nuances of accounting, economics and investing. He is very adept at technology and has worked closely with his brother on many of the technology and creative needs of GetOpp$.</p>
              </artical>
            </div> */}
          </div>
        </div>
      </div>
    </section>
    {/* End Mission Section */}
    {/* Start LeaderShip Section */}
    {/* <section className='leadership'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 text-center mb-4'>
            <h4 className='heading_Blue mb-3'>
              Company <b>LEADERSHIP</b>
            </h4>
          </div>
          <div className='col-md-12'>
            <div className='leaderShipContainer'>
              {
                data.length > 0 ?
                  <>
                    {
                      data.map((val, index) => {
                        return (
                          <div className='leaderShipArea d-block d-md-flex' key={index}>
                            <img src={Images.leader} className='me-3 mb-3' alt='UserImg' />
                            <div className='leaderContentArea'>
                              <h5 className='innerSubtext mb-1'><b>Marilyn F. Booker</b></h5>
                              <p className='innerSubtextBlue'>President, CEO</p>
                              <p className='innerSubtext'>
                                An attorney and 30+ year veteran of Wall Street, Marilyn is an expert in small and minority business having built out a world class supplier diversity program for one of the leading global financial services firms. She has also worked with all of the governmental and trade organizations that support the growth of small and diverse businesses.
                              </p>
                            </div>
                          </div>
                        )
                      })

                    }
              </> 
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* End LeaderShip Section */}
    {/* Start News&Press Section */}
    {/* <section className='newsPress' id="#press">
      <div id='press' className='container'>
        <div className='row'>
          <div className='col-md-12 text-center mb-4'>
            <h4 className="bannerHead mb-3">News & <b>PRESS</b></h4>
          </div>
        </div>
        <div className='row'>
          <OwlCarousel
            className='owl-theme'
            loop={true}
            margin={10}
            nav={false}
            responsiveClass={true}
            responsive={{
              0: {
                items: 1
              },
              600: {
                items: 1
              },
              991: {
                items: 2
              },
              1000: {
                items: 3
              }
            }}
          >
            <div className='item'>
              <div className='newsCard'>
                <h5 className='innerSubtextbold  mb-1'>
                  <b> New company bridging gap for small businesses</b>
                </h5>
                <p className='innerSubtextBlue'>February 10, 2023</p>
                <p className='innerSubtext'>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
                <div className='readMoreData'>
                  <Link to='' className='readMore'>Read More</Link>
                </div>
              </div>
            </div>
          </OwlCarousel>
          <div className='col-md-12 mt-5 text-center'>
            <button className='primaryBtn m-auto'>View All News
              <span className='ms-4'><i className="las la-angle-right"></i></span>
            </button>
          </div>
        </div>
      </div>
    </section> */}
    {/* End News&Press Section */}
  </div>
  )
}

export default AboutUs