import React, { useEffect } from 'react'
import * as Images from "../../../utilities/images";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "ContactUs";
    }, []);
    return (
        <>
            <section className='contectUs_ mt-5 mb-5'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='memeberHead_ col-md-10 '>
                            <h3 className='memberHead_'>Contact<strong> Us!</strong></h3>
                            <h5 className='discription_'>Embracing feedback and maintaining unwavering availability for our  clients,<br></br> we pave the path to excellence by
                                continuously evolving to meet your needs.</h5>
                        </div>
                    </div>
                    <div className="memberHomeImage_ mt-5 mb-4">
                        <form>
                            <div className='row'>
                                <div className='col-md-6 col-lg-5'>
                                    <div className='contactLeft'>
                                        <h3 className='contactHead_'>Contact <strong>Us!</strong></h3>
                                        <p className='paragraph_'>We're here and ready to listen – reach out to us; your thoughts and queries matter!</p>
                                        <div className='contactInfo_'>
                                            <h5 className='innerSubtext mb-3'><img className='contactIcon' src={Images.messageImg} /> <span>info@getopps.io</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 offset-lg-2 col-lg-5'>

                                    <div className='contactRight'>
                                        <div className="row">
                                            <div className=" mb-4 form-group">
                                                <input   type="text" className="form-control " name="text" placeholder="Name" />
                                            </div>
                                            <div className="mb-4 form-group">
                                                <input   type="email" className="form-control" name="email" placeholder="Email" />
                                            </div>
                                            <div className="mb-4 form-group">
                                                <textarea   className="form-control " cols="50" rows="3" placeholder="Message"></textarea>
                                            </div>
                                            <div className="form-group">
                                                <button type='submit' className="primaryBtn">Submit</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs