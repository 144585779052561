import React, { useState } from "react";
import { Link} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useAuthSelector } from "../../../redux/selector/auth";
import * as Images from "../../../utilities/images";

const AuthNav = () => {
    const authSelector = useAuthSelector()
    const authToken = localStorage.getItem("adminAuthToken") ? true : false;

    return (
        <>
            <div className="authNavBar">
                <nav className="navbarContain navInnerhaed">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-4 col-sm-6 col-md-6 col-lg-2">
                                <Nav.Link href="/"
                                    className="navbar-brand">
                                    <img src={Images.logo} alt='Logo' className="img-fluid" />
                                </Nav.Link>
                            </div>

                            <div className="col-8 col-sm-6 col-md-6 col-lg-10 text-end">
                                {authToken ?
                                    <Navbar expand="lg">
                                        <div className="rightNav_">
                                            <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                                                <i className="las la-bars barIcon"></i>
                                            </Navbar.Toggle>
                                            
                                            <ul className="authMenus">
                                                <li className="authItems">
                                                    <Link to="#" className="authLinks">
                                                        <img src={Images.envelope} alt="Envelope" className="authIcons" />
                                                    </Link>
                                                </li>
                                                <li className="authItems">
                                                    <Link to="#" className="authLinks  notification">
                                                        <img src={Images.bell} alt="Bell" className="authIcons" />
                                                    </Link>
                                                </li>
                                                <li className="authItems userDetails">
                                                    <Link className="authLinks">
                                                        <img src={authSelector?.userInfo?.user?.user_profile?.profile_photo ? authSelector?.userInfo?.user?.user_profile?.profile_photo : Images.companyprofile}
                                                            alt="User" className="userImg me-2" />
                                                        <span className="me-3 d-none d-md-inline">{authSelector?.userInfo?.user?.user_profile?.first_name} { authSelector?.userInfo?.user?.user_profile?.last_name?authSelector?.userInfo?.user?.user_profile?.last_name:""}
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className="authItems">
                                                    <Link to='#' className="authLinks"  >
                                                        <img src={Images.setting} alt="Setting" className="authIcons" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Navbar> : ""
                                }
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )

}

export default AuthNav;