import React, { useState, useEffect, useRef } from "react";
import * as Images from "../../../utilities/images";

const AccountDashboard = () => {

    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    return (
        <div className='containerBox adminDashbord'>
            <h3 className='headTxtBlueSmall mb-3'>Admin Dashboard</h3>
            <div className="row">
                <div className="col-md-8">
                    <div className="mb-5">
                        <h1 className="heading20">Traffic Overview</h1>
                        <p className="headTxtBlueSmall">1,245 <span className="subText13">Users</span></p>
                        <img src={Images.tarrifOverview} alt="tarrife overview" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-4">
                    <h1 className="heading20 mb-5">Traffic Details</h1>
                    <div className="trafficDetails mb-4">
                        <span className="timeSpot">New Sessions %</span>
                        <h1 className="heading48 my-2">61.1%</h1>
                        <span className="subText13 userTxtBold">1,245 <span className="fw-normal">Users</span></span>
                    </div>
                    <div className="trafficDetails">
                        <span className="timeSpot">Time on Site</span>
                        <h1 className="heading48 my-2">1m20s</h1>
                        <span className="subText13 userTxtBold">+9.09% <span className="fw-normal">Since Last Month</span></span>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-7">
                    <div className="taffifeSource">
                        <h1 className="heading20 mb-3">Traffic Source</h1>
                        <img src={Images.tarrifSource} alt="tarrife overview" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-5">
                    <h1 className="heading20 mb-3">Traffic Source</h1>
                    <img src={Images.tarrifSource2} alt="tarrife overview" className="img-fluid" />
                </div>
            </div>
        </div>
    )
}

export default AccountDashboard
