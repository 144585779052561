import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminLogout } from "../../../redux/slices/auth";
import { useDispatch } from 'react-redux';

const SideNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    // admin logout
    const logoutAdmin = (e) => {
        e.preventDefault();
        dispatch(adminLogout({
            cb(res) {
                if (res?.data) {
                    navigate("/")
                }
            }
        }))
    }

    return (
        <div className='containerBox adminSidebar'>
            <h3 className='headTxtBlueSmall mb-5'>Menu</h3>
            <ul className='settingMenus'>
                <li
                    className='sidebarItems'>
                    <Link to='dashboard' className='sidebarLinks'>
                        <img src={Images.dashIcon} alt='dashboard' />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.anaylicsIcon} alt='analytics' />
                        <span>Analytics</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.financialIcon} alt='financial' />
                        <span>Financial</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.promoIcon} alt='promo codes' />
                        <span>Promo Codes</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.kybIcon} alt='kyb' />
                        <span>KYB</span>
                    </Link>
                </li>
                <li
                    className='sidebarItems'>
                    <Link to='/support' className='sidebarLinks'>
                        <img src={Images.kybIcon} alt='kyb' />
                        <span>Support</span>
                    </Link>
                </li>
                <hr />
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.membershipIcon} alt='members list' />
                        <span>Members List</span>
                    </Link>
                </li>

                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.settingIcon} alt='setting Icon' />
                        <span>Setting</span>
                    </Link>
                </li>
                <hr />
                <li
                    className='sidebarItems'>
                    <Link to='#' className='sidebarLinks'>
                        <img src={Images.messageIcon} alt='message center' />
                        <span onClick={(e) => logoutAdmin(e)}>Logout</span>
                    </Link>
                </li>
            </ul>
            {path == "/support" ?
                <div className='advData my-5'>
                    <h4 className='headingSmall_ mb-3'>Advertising Data</h4>
                    <p className='headingTitleLight_'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                </div> :
                <div className='advData my-5'>
                    <h4 className='headingSmall_ mb-3'>This is the administratrive <br /> Dashboard</h4>
                    <p className='headingTitleLight_'>This is where you can manage all of the members, see their activity.</p>
                </div>
            }
        </div>


    )

}

export default SideNav;